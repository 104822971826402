/* eslint-disable react-hooks/rules-of-hooks */
import { CorpAnchorNavigation } from 'components/CorpAnchorNavigation';
// import { extensionComponentData } from "modules/extensionComponentData";
import { API_URL } from 'config/config';
import { getGenericPage } from 'lib/graphql/getGenericPages';
import { contentGroups } from 'lib/gtm/index';
import PageComponentMapper from 'modules/componentMapping/pageComponentMapper';
import { getLocale } from 'modules/localesMapper';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Markets } from '../generated/graphql';
import { IGlobalMetaData } from '../types/GlobalMetadata';
import Head from 'next/head';
import { doNotIndexPage } from '../lib/utils/helpers';
import Custom404 from './404';
import { INotificationBannerProps } from '../components/NotificationBanner/types';
import { SELECT_MARKET } from './_app';

function IndexPage({
  data,
  globalMetadata,
  market,
  locale,
  rewrites,
  error,
  apiUrl,
  dictionary,
}: {
  data: any;
  globalMetadata: IGlobalMetaData;
  env: string;
  market: Markets;
  locale: string;
  recallData: INotificationBannerProps;
  rewrites: Record<string, any>;
  error: boolean;
  apiUrl: string;
  dictionary: any;
}) {
  const page = data.genericPages?.[0] || {};
  const [query, setQuery] = useState<string>('');
  const router = useRouter();
  const [popularHits] = useState<any[]>([]);
  if (error) {
    return <Custom404 dictionary={dictionary} />;
  }

  useEffect(() => {
    if (query && popularHits) {
    }
    if (router.query?.query) {
      setQuery(router.query.query as string);
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (page?.contentGroupingTrackingValue) {
      if (page?.contentGroupingTrackingValues?.length > 0) {
        const tempStr = page?.contentGroupingTrackingValues.replaceAll('_', ' ');
        contentGroups({
          cg1: page?.contentGroupingTrackingValue.replaceAll('_', ' '),
          cg2: tempStr,
        });
      } else {
        contentGroups({
          cg1: page?.contentGroupingTrackingValue.replaceAll('_', ' '),
        });
      }
    }
  }, []);

  useEffect(() => {
    const checkBrowserLanguage = async () => {
      try {
        const browserLang = navigator.language;
        const { locale: localeRouter, asPath } = router;

        if (
          !asPath.includes(SELECT_MARKET) &&
          browserLang?.toLowerCase().includes('de') &&
          market !== 'de' &&
          localeRouter !== 'de'
        ) {
          await router.push('/', undefined, { locale: 'de' });
        }
      } catch (error) {
        console.error('Error detecting browser language:', error);
      }
    };

    checkBrowserLanguage().then();
  }, []);

  const links = page?.anchorNavigationList?.anchorNavigations?.map((nav: any) => ({
    id: nav.id,
    url: nav.navigationTitle.split(' ').join('-'),
    displayName: nav.navigationTitle,
  }));

  const noIndex = doNotIndexPage(page, market);
  // TODO - final data need to impliment

  // const extensionData = extensionComponentData(cardCarouselCMSData, rewrites);
  return (
    <>
      <Head>{noIndex && <meta name="robots" content="noindex,nofollow" />}</Head>
      {links?.length > 0 && <CorpAnchorNavigation links={links} />}
      <PageComponentMapper
        components={page?.pageComponents}
        copy={dictionary || null}
        rewrites={rewrites}
        apiUrl={apiUrl}
        metadata={page?.metaData}
        globalMetadata={globalMetadata}
      />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'global',
  locales,
  res,
}) => {
  let error = false;
  const slug = 'index';
  const market = locale; // locale from path is representing market
  const currLocale = getLocale(market);
  const genericPageData = await getGenericPage(market as Markets, currLocale, slug);
  const data = genericPageData?.data;
  const rewrites: any[] = [];
  if (!data?.genericPages) {
    error = true;
    res.statusCode = 404;
  }

  return {
    props: {
      currLocale,
      market,
      env: process.env.RECIPE_ENV?.toLowerCase() || 'dev',
      data: data || {},
      locales,
      locale,
      rewrites,
      recallData: null,
      error,
      apiUrl: API_URL,
    },
  };
};
export default IndexPage;
